import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import useOutsideObserver from '../../../utils/outsideObserver'
import { userLogout } from '../../../services/api/authApis'
import { useDispatch, useSelector } from 'react-redux'
import { resetAuth } from '../../../redux/reducers/authSlice'
import { deleteAccount } from '../../../services/api/myProfileApis'
import { toast } from 'react-toastify'
import DeleteConfirm from '../DeleteModal/DeleteConfirm'
import { useTranslation } from 'react-i18next'

const UserHeader = () => {
  const { t } = useTranslation();
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const [showDetails, setShowDetails] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [openHam, setOpenham] = useState(false)
  const detailsRef = useRef(null);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useOutsideObserver(detailsRef, () => setShowDetails(false));
  const logOutUser = () => {
    dispatch(resetAuth())
    userLogout()
    navigate('/login')
  }
  const gotoUserProfile = () => {
    navigate('/my-details')
  }

  const deleteUserProfile = async () => {
    let data = await deleteAccount()
    if (data) {
      toast.success(data.message)
      setShowDeleteModal(false)
      logOutUser()
    }
  }
  const changePassword = () => {
    navigate('/change-password')
  }
  return (
    <header className={`main-header ${scrollPosition > 0 && 'fixed'}`}>
      <div className="container">
        <div className="header-row" >
          <div className="logo">
            <NavLink to="/"> <img src="/images/logo.png" alt="" /> </NavLink>
          </div>
          <div className="hdr-rt" >
            <div className="main-menu" style={{ transform: openHam ? 'translateX(0px)' : '' }}>
              <div className="nav_close" onClick={() => setOpenham(false)}>
                <FontAwesomeIcon icon={["far", "fa-times-circle"]} color='white' />
              </div>
              <div className="menu-main-list">
                <ul>
                  <li className={`${location?.pathname === '/' && 'current-menu-item'}`}>
                    <NavLink to="/">{t("cms_text_header_userHome")}</NavLink>
                  </li>
                  <li className={`${location?.pathname === '/about' && 'current-menu-item'}`}>
                    <NavLink to="/about">{t("cms_text_header_userAbout")}</NavLink>
                  </li>
                  <li className={`${location?.pathname === '/services' && 'current-menu-item'}`}>
                    <NavLink to="/services">{t("cms_text_header_userServices")}</NavLink>
                  </li>
                  <li className={`${location?.pathname === '/contactus' && 'current-menu-item'}`}>
                    <NavLink to="/contactus">{t("cms_text_header_userContactUs")}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="log-div for-m">
              <ul>
                <li>
                  <button>
                    <span className="user-icon">
                      <FontAwesomeIcon icon={["fas", "user"]} />
                    </span>
                    log in
                  </button>
                </li>
                <li>
                  <button> <span className="user-icon">
                    <FontAwesomeIcon icon={["fas", "user"]} />
                  </span>
                    sign up</button>
                </li>
              </ul>
            </div> */}
            <div className="filter-box" ref={detailsRef}>
              <div className={`select-menu ${showDetails && 'active'}`} onClick={() => setShowDetails(!showDetails)}>
                <div className="select-btn">
                  <span className="sBtn-text">  <img src={!!user?.profile_image ? user?.profile_image : "images/profile-img-2.png"} alt="" /></span>
                </div>
                <ul className="options">
                  <li className="option user-name" onClick={gotoUserProfile}>
                    <div className="p-img-wrap">
                      <img src={!!user?.profile_image ? user?.profile_image : "images/profile-img-2.png"} alt="" />
                    </div>
                    <span className="option-text">{user?.first_name} {user?.last_name}</span>
                  </li>
                  <li className="option" onClick={logOutUser}>
                    <div className="p-img-wrap">
                      <img src="images/logout.png" alt="" />
                    </div>
                    <span className="option-text">{t("cms_text_header_userLogOut")}</span>
                  </li>
                  <li className="option" onClick={changePassword}>
                    <div className="p-img-wrap">
                      <FontAwesomeIcon icon={["fas", "key"]} color='#010039' />
                    </div>
                    <span className="option-text">{t("cms_text_header_userChangePassword")}</span>
                  </li>
                  <li className="option" onClick={() => setShowDeleteModal(true)}>
                    <div className="p-img-wrap">
                      <img src="images/delete.png" alt="" />
                    </div>
                    <span className="option-text">{t("cms_text_header_userDeleteAccount")}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="nav_btn" onClick={() => setOpenham(true)}>
              <FontAwesomeIcon icon={["fas", "bars"]} color='black' />
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirm showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} message="Do you want to delete your account?" confirmDelete={deleteUserProfile} />
    </header>
  )
}

export default UserHeader