import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { loginFromValidator } from '../../../services/formValidator/authFormValidator'
import { useDispatch, useSelector } from 'react-redux'
import { signIn } from '../../../services/api/authApis'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { dynamicDataSectionVise } from '../../../services/api/dynamicApi'
import loginPageImage from '../../../imagesForShowingError/images6.png'

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { user } = useSelector((state) => state.auth)
    const [showPassword, setShowPassword] = useState(false)
    const logInformik = useFormik({
        initialValues: loginFromValidator?.initialValues,
        validationSchema: loginFromValidator?.validationSchema,
        onSubmit: async (values) => {
            let data = await signIn(values)
            if (data) {
                toast.success(data?.message)
                navigate('/verifyemail', { state: { email: values?.email } })
            }
        }
    })

    useEffect(() => {
        if (user?.role) {
            navigate('/my-details')
        }
    }, [user])
    const [images, setImages] = useState()
    useEffect(() => {
        gettingDynamicData();
    }, []);
    const gettingDynamicData = async () => {
        let payloadData = {
            "section_ids": [
                "cms_section_sign_in",

            ]
        }
        let dataGet = await dynamicDataSectionVise(payloadData);
        setImages(dataGet?.data?.pageContentImages)
    };
    const replaceImage = (error) => {
        error.target.src = loginPageImage;
    };
    return (
        <div>
            <div className='loginwrap'>
                <div className="container-fluid p-0">
                    <div className="row m-0">
                        <div className="col-lg-5 p-0">
                            <div className='auth-img-wrap-left'>
                                <img src={images?.cms_image_sign_in} onError={replaceImage} alt="" />
                                <NavLink to="/">
                                    <div className="home-img">
                                        <FontAwesomeIcon icon={["fa", "home"]} size={50} />
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-lg-7 p-0">
                            <div className="right-login-wrap">
                                <div className="right-login">
                                    <h2>{t("cms_text_sign_in_title")}</h2>
                                    <div className="auth-img-wrap-right">
                                        <div className='contact-details-right-wrap'>
                                            <div className="Contact-with-us">
                                                <form onSubmit={logInformik?.handleSubmit}>
                                                    <div className="row">


                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Email address</label>
                                                                <input type="email" onChange={logInformik?.handleChange} value={logInformik?.values?.email} placeholder="Enter your email address" id='email' name='email' className="form-control" />
                                                                <p className='form-error-message'>{logInformik.touched.email &&
                                                                    logInformik.errors.email}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Password</label>
                                                                <input type={showPassword ? "text" : "password"} onChange={logInformik?.handleChange} value={logInformik?.values?.password} placeholder="Enter your password" id='password' name='password' className="form-control" />
                                                                <div className='eye-btn'>
                                                                    <FontAwesomeIcon icon={['fa', `${showPassword ? 'eye' : 'eye-slash'}`]} onClick={() => setShowPassword(!showPassword)} color='gray' style={{ cursor: 'pointer' }} />
                                                                </div>
                                                                <p className='form-error-message'>{logInformik.touched.password &&
                                                                    logInformik.errors.password}</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <div className='forget-p'>
                                                                <p>Forgot your password? <NavLink to="/forgotpassword">Recover password</NavLink></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <button type="submit" className="btn">
                                                                    Sign in
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </form>
                                            </div>
                                            <div className="auth-bottom">
                                                <div className='or'>
                                                    or
                                                </div>
                                                <div className='here-p'>
                                                    <p>Don't have an account? <NavLink to='/signup'>Sign up here</NavLink></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default Login