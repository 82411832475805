import AuthLayout from "../layouts/AuthLayout";
import UserLayout from "../layouts/UserLayout";
import VisitorLayout from "../layouts/VisitorLayout";
import MydetailsIndex from "../pages/User/myDetails";
import AboutIndex from "../pages/Visitor/About";
import ContactIndex from "../pages/Visitor/Contact";
import HomeIndex from "../pages/Visitor/Home";
import PrivacyPolicyIndex from "../pages/Visitor/PrivacyPolicy";
import ServicesIndex from "../pages/Visitor/Services";
import ServicesDetailsIndex from "../pages/Visitor/ServicesDetails";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import VerifyEmailIndex from "../pages/auth/VerifyEmail";
import PasswordVerifyIndex from "../pages/auth/PasswordVerify";
import ResetPasswordIndex from "../pages/auth/ResetPassword";
import NoContentPage from "../components/common/NoContentPage/NoContentPage";
import ChangePassword from "../pages/User/changePassword";
import TermsAndConditions from "../pages/Visitor/TermsConditions";
/**
 * *Import component and create a route by define a path.
 *
 */
const AuthLayoutFunc = (comp) => {
  return <AuthLayout>{comp}</AuthLayout>;
};
const UserLayoutFunc = (comp) => {
  return <UserLayout>{comp}</UserLayout>;
};
const VisitorLayoutFunc = (comp) => {
  return <VisitorLayout>{comp}</VisitorLayout>;
};
const routes = [
  // Auth routes
  {
    path: "/login",
    element: AuthLayoutFunc(<Login />),
    protected: false,
    role: [],
  },
  {
    path: "/signup",
    element: AuthLayoutFunc(<SignUp />),
    protected: false,
    role: [],
  },
  {
    path: "/forgotpassword",
    element: AuthLayoutFunc(<ForgotPassword />),
    protected: false,
    role: [],
  },
  {
    path: "/verifyemail",
    element: AuthLayoutFunc(<VerifyEmailIndex />),
    protected: false,
    role: [],
  },

  {
    path: "/passwordverify",
    element: AuthLayoutFunc(<PasswordVerifyIndex />),
    protected: false,
    role: [],
  },
  {
    path: "/resetPassword",
    element: AuthLayoutFunc(<ResetPasswordIndex />),
    protected: false,
    role: [],
  },

  {
    path: "/",
    element: VisitorLayoutFunc(<HomeIndex />),
    protected: false,
    role: [],
  },
  {
    path: "/about",
    element: VisitorLayoutFunc(<AboutIndex />),
    protected: false,
    role: [],
  },
  {
    path: "/services",
    element: VisitorLayoutFunc(<ServicesIndex />),
    protected: false,
    role: [],
  },
  {
    path: "/services-details",
    element: VisitorLayoutFunc(<ServicesDetailsIndex />),
    protected: false,
    role: [],
  },
  {
    path: "/contactus",
    element: VisitorLayoutFunc(<ContactIndex />),
    protected: false,
    role: [],
  },
  {
    path: "/privacypolicy",
    element: VisitorLayoutFunc(<PrivacyPolicyIndex />),
    protected: false,
    role: [],
  },
  {
    path: "/terms_conditions",
    element: VisitorLayoutFunc(<TermsAndConditions />),
    protected: false,
    role: [],
  },
  // User routes
  {
    path: "/my-details",
    element: UserLayoutFunc(<MydetailsIndex />),
    protected: true,
    role: ["user"],
  },
  {
    path: "/change-password",
    element: UserLayoutFunc(<ChangePassword />),
    protected: true,
    role: ["user"],
  },
  {
    path: "*",
    element: <NoContentPage />,
  },
];

export default routes;
