import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'

const VisitorFooter = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer-main position-relative">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="footer-info">
                                <div className="footer-logo">
                                    <NavLink to="/">  <img src="images/footer-logo.png" alt="" /></NavLink>

                                </div>
                                <p>
                                    {t("cms_text_footer_user001")}
                                </p>
                                <ul className="Footer-social">
                                    <li>
                                        <a href={`${t("cms_text_footer_facebook_link")}`} target='_blank' className="f-icon"> <FontAwesomeIcon icon={["fab", "fa-facebook-f"]} /> </a>

                                    </li>
                                    <li>
                                        <a href={`${t("cms_text_footer_twitter_link")}`} target='_blank' className="f-icon"> <FontAwesomeIcon icon={["fab", "fa-twitter"]} /> </a>

                                    </li>
                                    <li>
                                        <a href={`${t("cms_text_footer_instagram_link")}`} target='_blank' className="f-icon"> <FontAwesomeIcon icon={["fab", "fa-instagram"]} /> </a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="footer-info">
                                <h4>{t("cms_text_footer_user002")}</h4>
                                <ul>
                                    <li>

                                        <NavLink to="/">{t("cms_text_footer_user003")}</NavLink>
                                    </li>
                                    <li>

                                        <NavLink to="/about">{t("cms_text_footer_user004")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/services">{t("cms_text_footer_user005")}</NavLink>

                                    </li>
                                    <li>
                                        <NavLink to="/contactus">{t("cms_text_footer_user006")}</NavLink>

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="footer-info">
                                <h4>{t("cms_text_footer_user007")}</h4>
                                <ul>
                                    <li>
                                        <NavLink to="/terms_conditions">{t("cms_text_footer_user008")}</NavLink>

                                    </li>
                                    <li>
                                        <NavLink to="/privacypolicy">{t("cms_text_footer_user009")}</NavLink>

                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="footer-info">
                                <h4>{t("cms_text_footer_user010")}</h4>
                                <ul>
                                    <li>
                                        <a href={`tel:${t("cms_text_footer_user011")}`}><FontAwesomeIcon icon={["fa", "fa-phone-alt"]} />{t("cms_text_footer_user011")}</a>

                                    </li>
                                    <li>
                                        <a href={`mailto:${t("cms_text_footer_user012")}`}><FontAwesomeIcon icon={["fa", "fa-envelope"]} /> {t("cms_text_footer_user012")}</a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right text-center">
                <p>{t("cms_text_footer_user013")}</p>
            </div>
        </footer>

    )
}

export default VisitorFooter