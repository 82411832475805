import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { signInWithOtp } from '../../../redux/reducers/authSlice';
import { dynamicDataSectionVise } from '../../../services/api/dynamicApi';
import { useTranslation } from 'react-i18next';
import verifyEmailPageImage from '../../../imagesForShowingError/images2.jpg'

const VerifyEmailIndex = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { user } = useSelector(state => state.auth)
    const [otp, setOtp] = useState(null)
    const [error, setError] = useState('')
    const { t } = useTranslation();

    const verifyOtpFunc = async (e) => {
        e.preventDefault()
        if (!otp || otp?.length < 6) {
            toast.error('Otp must be at least 6 numbers')
        }
        else {
            let bodyData = {
                email: location?.state.email,
                otp: Number(otp)
            }
            dispatch(signInWithOtp(bodyData))
        }
    }

    useEffect(() => {
        if (user?.role) {
            navigate('/my-details')
        }
    }, [user])

    useEffect(() => {
        if (!location?.state?.email) {
            navigate('/login')
        }
    }, [location?.state?.email])
    const hanldeOtp = (e) => {
        if (Number(e)) {
            setOtp(e)
            setError('')
        }
        else {
            setError('OTP must be number')
        }
    }
    const [images, setImages] = useState()
    useEffect(() => {
        gettingDynamicData();
    }, []);
    const gettingDynamicData = async () => {
        let payloadData = {
            "section_ids": [
                "cms_section_otp",

            ]
        }
        let dataGet = await dynamicDataSectionVise(payloadData);
        setImages(dataGet?.data?.pageContentImages)
    };
    const replaceImage = (error) => {
        error.target.src = verifyEmailPageImage;
    };

    return (
        <div className='loginwrap verifyemail'>
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-lg-5 p-0">
                        <div className='auth-img-wrap-left'>
                            <img src={images?.cms_image_otp} onError={replaceImage} alt="" />
                            <NavLink to="/">
                                <div className="home-img">
                                    <FontAwesomeIcon icon={["fa", "home"]} size={50} />
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="col-lg-7 p-0">
                        <div className="right-login-wrap">
                            <div className="right-login">
                                <h2>{t("cms_text_otp_title")}</h2>
                                <div className="auth-img-wrap-right">
                                    <div className='contact-details-right-wrap'>
                                        <div className="Contact-with-us">
                                            <form onSubmit={verifyOtpFunc}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label >Enter the code</label>
                                                            <OtpInput
                                                                inputType='text'
                                                                value={otp}
                                                                onChange={hanldeOtp}
                                                                numInputs={6}
                                                                containerStyle={'otp-main'}
                                                                renderSeparator={<span className='gap-span'> </span>}
                                                                renderInput={(props) => <input {...props} />}
                                                            />
                                                            <p className='form-error-message'>{error}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="buton" className="btn">
                                                                Verify to sign in
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default VerifyEmailIndex;
